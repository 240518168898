<div *ngIf="memberPortalFooter" id="csContactInfoPnl" name="csContactInfoPnl">
  <div class="row csfooter" >
    <div class="col-md-12">
      <h4 id="csMainMsgLbl" name="csMainMsgLbl">
        {{
          memberPortalFooter?.MainMessage ||
            "if you have questions or need help with your health care services, please call calOptima's customer service department."
        }}
      </h4>
    </div>
  </div>
  <div class="row csfooter" id="csContactInfoLbl" name="csContactInfoLbl">
    <div class="col-md-4" id="csPhoneLbl" name="csPhoneLbl">
      <span class="fa fa-phone fa-2x primary"></span><br />
      <h3>{{ memberPortalFooter?.PhoneNumber || '1-888-587-8088' }}</h3>
      {{ memberPortalFooter?.PhoneNumberMessage || 'toll-free customer service' }}
    </div>
    <div class="col-md-4" id="csAddressHoursLbl" name="csAddressHoursLbl">
      <span class="fa fa-building-o fa-2x primary"></span><br />
      <h4 class="csaddress">
        {{ memberPortalFooter?.Address || '505 City Parkway West' }}
      </h4>
      <h4 class="csaddress">
        {{ memberPortalFooter?.CityStateZip || 'Orange, CA 92868' }}
      </h4>
      {{ memberPortalFooter?.OfficeHours || '8 am to 5 pm' }}
    </div>
    <div class="col-md-4" id="csHoursOpperationLbl" name="csHoursOpperationLbl">
      <span class="fa fa-clock-o fa-2x primary"></span><br />
      <h3>{{ memberPortalFooter?.HoursOfOperation || '8 a.m. to 5:30 p.m.' }}</h3>
      {{ memberPortalFooter?.DaysOfOperation || 'monday through friday' }}
    </div>
  </div>
  <div class="row csfooter">
    <div class="col-md-12 csfooterspacer" [innerHtml]="memberPortalFooter?.BottomText"></div>
  </div>
</div>
