// truncate.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe {
    transform(value: string, strLength: string, suffix: string): string {
        if (value) {
            let limit: string = strLength ? strLength : '10';
            let trail: string = suffix ? suffix : '...';
            let ln: number = parseInt(limit);
            return value.length > ln ? value.substring(0, ln) + trail : value;
        }
        else {
            return '';
        }
    }
}