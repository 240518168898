<ul class="navbar-nav navbar-mega" appToggleElement="offcanvasSideNav">
  <li class="nav-item" routerLinkActive="active">
    <a
      href="#"
      id="lnkDashboard"
      class="nav-link"
      routerLink="/dashboard"
      data-bs-dismiss="offcanvas"
      target="_self"
    >
      {{ navigatorContent.HomeRouteLabel || "home" }}</a
    >
  </li>
  <li
    class="nav-item topnav-item dropdown"
    *ngIf="memberProfile?.IsEligible"
  >
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarDropdownSurvey"
      role="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ navigatorContent.SurveysRouteLabel || "surveys" }}
    </a>
    <ul class="dropdown-menu topnav-top" aria-labelledby="navbarDropdownSurvey">
      <li *ngIf="memberProfile?.IsEligible && !memberProfile?.IsMinor">
        <a
          href="#"
          id="lnkHealthWellness"
          class="dropdown-item"
          [routerLink]="['/surveys/management', 3]"
          target="_self"
          data-bs-dismiss="offcanvas"
        >
          {{ navigatorContent.HealthWellnessRouteLabel || "health & wellness" }}
        </a>
      </li>
      <li *ngIf="memberProfile?.IsEligible">
        <a
          href="#"
          id="lnkHealthInfoForm"
          class="dropdown-item"
          [routerLink]="['/surveys/management', 4]"
          target="_self"
          data-bs-dismiss="offcanvas"
        >
          {{
            navigatorContent.HealthInfoFormRouteLabel || "social needs survey"
          }}
        </a>
      </li>
      <li *ngIf="memberProfile?.IsEligible && showSurveyLink(5)">
        <a
          href="#"
          id="lnkSogi"
          class="dropdown-item"
          [routerLink]="['/surveys/management', 5]"
          target="_self"
          data-bs-dismiss="offcanvas"
        >
          {{ navigatorContent.SogiRouteLabel || "sexual orientation and gender identity" }}
        </a>
      </li>
    </ul>
  </li>
  <li
    class="nav-item topnav-item dropdown"
    *ngIf="!disableForPersonalRepresentative"
  >
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarDropdownService"
      role="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ navigatorContent.OnlineServicesLabel || "services" }}
    </a>
    <ul
      class="dropdown-menu topnav-top"
      aria-labelledby="navbarDropdownService"
    >
      <li *ngIf="memberProfile?.IsEligible">
        <a
          href="#"
          id="lnkGeneralInquiry"
          class="dropdown-item"
          routerLink="/generalinquiry"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{
            navigatorContent.GeneralInquiryRouteLabel ||
              "contact customer service"
          }}</a
        >
      </li>

      <li
        *ngIf="
          memberProfile?.IsEligible &&
          memberProfile?.LOB.LOBCode != this.configuration.PaceLOBCode
        "
      >
        <a
          href="#"
          id="lnkPrintIdCard"
          class="dropdown-item"
          routerLink="/printidcard"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{ navigatorContent.PrintIdCardRouteLabel || "print id card" }}</a
        >
      </li>
      <li *ngIf="memberProfile?.IsEligible">
        <a
          href="#"
          id="lnkRequestIdCard"
          class="dropdown-item"
          routerLink="/requestidcard"
          target="_self"
          data-bs-dismiss="offcanvas"
        >
          {{ navigatorContent.RequestIdCardRouteLabel || "request id card" }}</a
        >
      </li>
      <li
        *ngIf="
          memberProfile?.IsEligible &&
          memberProfile?.LOB.LOBCode != this.configuration.PaceLOBCode
        "
      >
        <a
          href="#"
          id="lnkUpdateMemberProfile"
          class="dropdown-item"
          data-bs-dismiss="offcanvas"
          routerLink="/updatememberprofile"
          target="_self"
          >{{
            navigatorContent.UpdateMemberProfileRouteLabel ||
              "update member profile"
          }}</a
        >
      </li>
      <li *ngIf="memberProfile?.IsEligible && showInterpretiveServiceLink()">
        <a
          href="#"
          id="lnkInterpretiveService"
          class="dropdown-item"
          routerLink="/interpretiveservice"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{
            navigatorContent.InterpretiveServiceRouteLabel ||
              "interpretive service"
          }}</a
        >
      </li>
      <li *ngIf="memberProfile?.IsEligible && showChangePCPHealthNetworkLink()">
        <a
          href="#"
          id="lnkChangePcpChangeHealthNetwork"
          class="dropdown-item"
          routerLink="/changepcpchangehealthnetwork"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{
            navigatorContent?.ChangePcpChangeHealthNetworkRouteLabel ||
              "change pcp/ change health network"
          }}</a
        >
      </li>
      <li>
        <a
          href="#"
          id="lnkAuthorization"
          class="dropdown-item"
          routerLink="/authorizations"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{
            navigatorContent.AuthorizationsRouteLabel ||
              "authorizations & referrals"
          }}</a
        >
      </li>
      <li>
        <a
          href="#"
          id="lnkImportantLinks"
          class="dropdown-item"
          routerLink="/importantDocumentsLink"
          data-bs-dismiss="offcanvas"
          target="_self"
          >{{
            navigatorContent.ImportantLinksRouteLabel ||
              "plan document and forms"
          }}</a
        >
      </li>
      <li *ngIf="memberProfile?.IsEligible &&
                 !this.disableVirtualVisitsLink &&
                 memberProfile?.LOB.LOBCode != this.configuration.PaceLOBCode">
        <a
          href="#"
          id="lnkbhVirtialVisits"
          class="dropdown-item"
          routerLink="/bhvirtualvisits"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{
            navigatorContent?.VirtualVisitsRouteLabel ||
              "virtual visits"
          }}
          </a
        >
      </li>
    </ul>
  </li>

  <li
    class="nav-item topnav-item dropdown"
    *ngIf="
      memberProfile?.IsEligible &&
      memberProfile?.LOB.LOBCode != this.configuration.PaceLOBCode &&
      !disableForPersonalRepresentative
    "
  >
    <a
      href="#"
      id="lnkProviderDirectory"
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ navigatorContent.FindProviderLabel || "provider directory" }}
    </a>
    <ul class="dropdown-menu topnav-top" aria-labelledby="navbarDropdown">
      <a
        href="{{ memberProviderLinks.ProviderSearchSitecoreLink }}"
        id="lnkProviderSearchSitecoreLink"
        class="dropdown-item"
        target="_blank"
      >
        {{
          navigatorContent.SearchForProviderRouteLabel || "search for provider"
        }}
      </a>
      <a
        href="{{ memberProviderLinks.ProviderFacilitySitecoreLink }}"
        id="lnkProviderProviderFacilitySitecoreLink"
        class="dropdown-item"
        *ngIf="memberProfile?.IsEligible"
        target="_blank"
      
      >
        {{
          navigatorContent.SearchForFacilityRouteLabel || "search for facility"
        }}
      </a>
      <a
        href="{{ memberProviderLinks.ProviderBehavioralHealthSitecoreLink }}"
        id="lnkProviderBehavioralHealthSitecoreLink"
        class="dropdown-item"
        *ngIf="memberProfile?.IsEligible"
        target="_blank"
      >
        {{
          navigatorContent.SearchForBehavioralHealthRouteLabel ||
            "search for behavioral health"
        }}
      </a>
      <a
        href="{{ memberProviderLinks.ProviderPharmacySitecoreLink }}"
        id="lnkProviderPharmacySitecoreLink"
        class="dropdown-item"
        *ngIf="memberProfile?.IsEligible"
        target="_blank"
      >
        {{
          navigatorContent.SearchForPharmacyRouteLabel || "search for pharmacy"
        }}
      </a>
      <a
        href="{{ memberProviderLinks.ProviderVisionSitecoreLink }}"
        id="lnkProviderVisionSitecoreLink"
        class="dropdown-item"
        *ngIf="memberProfile?.IsEligible"
        target="_blank"
      >
        {{ navigatorContent.SearchForVisionRouteLabel || "search for vision" }}
      </a>
      <a
        href="{{ memberProviderLinks.ProviderUrgentSitecoreLink }}"
        id="lnkProviderUrgentSitecoreLink"
        class="dropdown-item"
        *ngIf="memberProfile?.IsEligible"
        target="_blank"
      >
        {{ navigatorContent.SearchForUrgentRouteLabel || "search for urgent" }}
      </a>
    </ul>
  </li>

  <li class="nav-item topnav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarDropdownUserSetting"
      role="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i title="User Profile" class="fa fa-cog" alt="User Profile"></i>
    </a>
    <ul
      class="dropdown-menu topnav-top dropdown-menu-right"
      aria-labelledby="navbarDropdownUserSetting"
    >
      <li *ngIf="!disableForPersonalRepresentative">
        <a
          href="#"
          class="dropdown-item"
          id="lnkMemberProfile"
          routerLink="/profile"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{ navigatorContent.MemberProfileRouteLabel || "my profile" }}</a
        >
      </li>
      <li>
        <a
          href="#"
          id="lnkUserProfile"
          class="dropdown-item"
          routerLink="/user/userprofile"
          data-bs-dismiss="offcanvas"
          target="_self"
        >
          {{ navigatorContent?.UserProfileRouteLabel || "user profile" }}</a
        >
      </li>

      <li
        class="dropdown-item"
        id="lnkLogout"
        appToggleElement="offcanvasSideNav"
        (click)="logOut()"
      >
        {{ navigatorContent?.LogoutRouteLabel || "logout" }}
      </li>
    </ul>
  </li>

  <app-language appToggleElement="offcanvasSideNav"></app-language>
  
</ul>


<div *ngIf="memberProfile">
  <ng-container #medImpactComponent></ng-container>
</div>
