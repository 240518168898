<div *ngIf="!properties.ReadOnly" class="address-fixed-box">
    <form #addressForm="ngForm">
        <fieldset [disabled]="properties.Disabled">
            <h4>{{ properties.HeaderText|| "address header"}}</h4>
            <div *ngFor="let disclaimar of properties.TopDisclaimars">
                <div class="alert alert-info">   <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;{{disclaimar}}</div>
            </div>

            <div class="form-group" 
                [ngClass]="{'is-invalid': addressLine1.errors && addressLine1.touched && addressLine1.dirty ||properties.AddressLine1CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed}">
                
                <label class="col-form-label" for="addressline1">
                    {{properties.AddressLine1LabelText || "address Line 1"}}
                </label>
                
                <input 
                    id="addressLine1"
                    name="addressLine1" 
                    type="text" 
                    class="form-control" 
                    #addressLine1="ngModel"
                    [(ngModel)]="address.Address1" 
                    (ngModelChange)="addressLine1OnChange(addressLine1);" 
                    [ngClass]="{'is-invalid': addressLine1.errors && addressLine1.touched && addressLine1.dirty ||properties.AddressLine1CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed}"
                    (focusout)="addressLine1OnChange(addressLine1);" required uppercaseInput
                    />
                
                <label *ngIf="addressLine1.hasError('required') && addressLine1.touched && addressLine1.dirty" 
                    class="invalid-feedback">
                    {{properties.ValidationMessages.AddressLine1Required||'address line 1 is required'}}
                </label>

                <label *ngIf="properties.AddressLine1CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed "
                        class="invalid-feedback" for="addressLine1"> 
                    {{properties.ValidationMessages.AddressLine1ExceededMaxCharacter|| 'address line 1 has exceeded the number of characters allowed.'}}
                </label>

                <label class="control-message control-label pull-right">{{properties.AddressLine1CharacterCount}}/{{properties.AddressLineMaxNumberOfCharactersAllowed}}</label>
            </div>
   
            <div class="form-group" 
                [ngClass]="{'is-invalid': addressLine2.errors && addressLine2.touched && addressLine2.dirty ||properties.AddressLine2CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed}">
                <label for="addressLine2" class="control-label">
                    {{properties.AddressLine2LabelText || "address Line 2"}}
                </label>
                <input 
                    id="addressLine2"     
                    name="addressLine2" 
                    #addressLine2="ngModel" 
                    class="form-control" 
                    type="text" 
                    [(ngModel)]="address.Address2" 
                    (ngModelChange)="addressLine2OnChange(addressLine2);" 
                    [ngClass]="{'is-invalid': addressLine2.errors && addressLine2.touched && addressLine2.dirty || properties.AddressLine2CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed}"
                    (focusout)="addressLine2OnChange(addressLine2);" uppercaseInput />

                <label *ngIf="properties.AddressLine2CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed "
                    class="invalid-feedback" for="addressLine1"> 
                    {{properties.ValidationMessages.AddressLine2ExceededMaxCharacter|| 'address line 2 has exceeded the number of characters allowed.'}}
                </label>
                <label class="control-message control-label pull-right">{{properties.AddressLine2CharacterCount}}/{{properties.AddressLineMaxNumberOfCharactersAllowed}}</label>
            </div>
         
            <div class="form-group" 
                [ngClass]="{'is-invalid': city.errors && (city.touched || city.dirty) || properties.CityCharacterCount > properties.CityMaxNumberOfCharacterAllowed}">
                <label class="control-label">
                    {{properties.CityLabelText ||'city'}}
                </label>
                <input 
                    type="text" 
                    [(ngModel)]="address.City" 
                    #city="ngModel" 
                    class="form-control" 
                    name="city" 
                    required 
                    pattern="[^0-9]*" 
                    (ngModelChange)="cityOnChange(city);"
                    [ngClass]="{'is-invalid': city.errors && (city.touched || city.dirty) || properties.CityCharacterCount > properties.CityMaxNumberOfCharacterAllowed}" 
                    (focusout)="cityOnChange(city);" 
                    uppercaseInput />

                <label *ngIf="city.hasError('required') && city.touched && city.dirty" 
                    class="invalid-feedback">
                    {{properties.ValidationMessages.CityRequired||'city is required'}}
                </label>
                <label *ngIf="city.hasError('pattern') && (city.touched || city.dirty)" 
                    class="invalid-feedback">
                    {{properties.ValidationMessages.CityInvalid||'city is invalid'}}
                </label>
                <label *ngIf="properties.CityCharacterCount > properties.CityMaxNumberOfCharacterAllowed"
                    class="invalid-feedback" for="city"> 
                    {{properties.ValidationMessages.CityExceededMaxCharacter|| 'you have exceed the maximum characters allowed.'}}
                </label>
                <label class="control-message control-label pull-right">{{properties.CityCharacterCount}}/{{properties.CityMaxNumberOfCharacterAllowed}}</label>
            </div>
     
            <!-- State Zip -->
            <div class="row">
                <div class="form-group form-group-md col-md-5" 
                    [ngClass]="{'is-invalid': state.errors && state.touched && state.dirty}">
                    <label class="control-label">
                        {{properties.StateLabelText || 'state'}}
                    </label>
                    <select 
                        [(ngModel)]="address.State" 
                        id="state" 
                        name="state" 
                        type="text" 
                        class="selectState form-control" 
                        required 
                        maxlength="512"
                        [ngClass]="{'is-invalid': state.errors && state.touched && state.dirty}"
                        #state="ngModel">
                        <option>---Select---</option>
                        <option *ngFor="let state of States"
                                [ngValue]="state.abbreviation">
                            {{state.abbreviation}}
                        </option>
                    </select>
                    <label 
                        *ngIf="state.hasError('required') && state.touched && state.dirty" 
                        class="invalid-feedback">
                        {{properties.ValidationMessages.StateRequired||'state is required'}}
                    </label>
                </div>

                <div class="form-group form-group-md col-md-7" 
                    [ngClass]="{'is-invalid': zipcode.errors && zipcode.touched && zipcode.dirty}">
                    <label class="control-label">
                        {{properties.ZipCodeLabelText ||'zip code'}}
                    </label>
                    <input 
                        type="text" 
                        [ngModel]="address.ZipCode | zipcode" 
                        #zipcode="ngModel" 
                        class="form-control" 
                        name="zipcode" 
                        required 
                        pattern="^[0-9]{5}(?:-[0-9]{4})?$" 
                        placeholder="_____ -____ " 
                        maxlength="10"
                        [ngClass]="{'is-invalid': zipcode.errors && zipcode.touched && zipcode.dirty}"
                        zipcode 
                        (ngModelChange)="address.ZipCode=$event" />
                    <label *ngIf="zipcode.hasError('required') && zipcode.touched && zipcode.dirty" 
                        class="invalid-feedback" >
                        {{properties.ValidationMessages.ZipCodeRequired||'zipcode is required'}}
                    </label>
                    <label *ngIf="zipcode.hasError('pattern') && zipcode.touched && zipcode.dirty" 
                        class="invalid-feedback">
                        {{properties.ValidationMessages.ZipCodeInvalid||'zipcode is invalid'}}
                    </label>
                </div>
            </div>

            <!-- give consent to forward-->
            <div class="row" *ngIf="properties.ShowConsentInputSwitch">
                <div class="col-md-9">
                    <span class="inputswitch-switchlabel">
                        {{properties.ConsentLabelText||'do we have your consent to forward new address to Orange County Social Services Agency?'}}
                    </span>
                </div>
                <div class="col-md-3 text-right">
                    <p-inputSwitch 
                       
                        name="hasAcceptedConsent" 
                        #hasAcceptedConsent="ngModel" 
                        [(ngModel)]="SSAConsent" 
                        (onChange)="inputSwitchChanged()"
                        >
                    </p-inputSwitch>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div *ngFor="let disclaimar of properties.BottomDisclaimars">
                        <div class="alert alert-info">
                            <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                            {{disclaimar}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- buttons -->
            <div class="row" *ngIf="properties.ButtonsVisible">
                    <div class = "address-fixed-footer">
                        <div class="col-12 text-right" >         
                            <button class="btn btn-link" (click)="onCancel();addressForm.form.markAsPristine(); ">{{properties.CancelButtonText || 'cancel'}}</button>
                            <button 
                                class="btn btn-primary" 
                                type="submit" 
                                (click)="onUpdate();addressForm.form.markAsPristine();" 
                                [disabled]="!addressForm.form.valid || 
                                    !(addressLine1.dirty || addressLine2.dirty || city.dirty || state.dirty || zipcode.dirty) ||
                                    properties.AddressLine1CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed ||
                                    properties.AddressLine2CharacterCount>properties.AddressLineMaxNumberOfCharactersAllowed || 
                                    properties.CityCharacterCount > properties.CityMaxNumberOfCharacterAllowed ">
                                {{properties.UpdateButtonText || 'update'}}
                            </button>    
                        </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div *ngIf="properties.ReadOnly">
        <h4 *ngIf='properties.HeaderText'>{{properties.HeaderText}}</h4>
        <div>
            <div>{{address.Address1}}</div>
            <div>{{address.Address2}}</div>
            <div>
                <span>{{address.City}}</span>
                <span>,</span>&nbsp;
                <span>{{address.State}}</span>&nbsp;
                <span>{{address.ZipCode | zipcode}}</span>&nbsp;
            </div>
        </div>
</div>



