import { Injectable, OnInit } from "@angular/core";
import { AppConfig } from "@caloptima/portal-foundation";
import { SecurityQuestion } from "./user/shared/security-question";
import { KeyValue } from "./user/shared/keyvalue";
import { RegistrationTemplate } from "./user/shared/registration-template";

@Injectable()
export class Configuration {
  public Environment: string;
  public static readonly ENV = {
    DEV: "dev",
    UAT: "uat",
    STG: "stg",
    PROD: "prod",
  };
  public static readonly CardType = {
    UnAuthenticated: "UnAuthenticated",
    MediCal: "MediCal",
    OCC: "OCC",
    OneCare: "OneCare",
    Pace: "Pace",
    ViewOnly_UnAuthenticated: "ViewOnly_UnAuthenticated",
    ViewOnly_MediCal: "ViewOnly_MediCal",
    ViewOnly_OCC: "ViewOnly_OCC",
    ViewOnly_OneCare: "ViewOnly_OneCare",
    ViewOnly_Pace: "ViewOnly_Pace",
  };
  // Region specific base URLs
  public BaseMemberPortalUrl: string;
  public BaseMemberServiceUrl: string;
  public BaseSecureAuthUrl: string;
  public BaseSitecoreServiceUrl: string;
  public BaseSitecoreUrl: string;
  public MemberPrivacyPolicyUrl: string;
  public MemberNoticeOfPrivacyPracticesUrl: string;

  public BaseAuthenticationServiceUrl: string;
  // Region specific constants
  public RegionClientId: string;

  // Region specific redirect URLs
  public RegionRedirectUri: string;

  // OAuth Configuration
  public ClientId: string;
  public Scope: string;
  public Issuer: string;
  public Oidc: boolean;
  public IdpServer: string;

  // Services url
  public UserProfileUrl: string;
  public LoginUrl: string;
  public LogoutUrl: string;
  public SessionTimeOutUrl: string;
  public SitecoreContentServiceUrl: string;
  public MemberServiceUrl: string;
  public MemberProfileServiceUrl: string;
  public RequestIDCardServiceUrl: string;
  public GeneralInquiryServiceUrl: string;
  public InterpretiveServiceUrl: string;
  public TCPAUpdateUrl: string;
  public MemberMessagesUrl: string;
  public MemberMessageUpdateUrl: string;
  public MemberIdCardServiceUrl: string;
  public RequestPrintMemberIdCardUrl: string;
  public RequestDownloadMemberIdCardUrl: string;
  public UpdateMemberProfileServiceUrl: string;
  public MemberRegistrationCompleteUrl: string;
  public MemberLanguagesServiceUrl: string;
  public FamilyRelationshipServiceUrl: string;
  public AlertServiceUrl: string;
  public RedirectUri: string;
  public SendEmailServiceUrl: string;
  public ProviderServiceUrl: string;
  public GetHealthNetworkServiceUrl: string;
  public GetProviderDetailsServiceUrl: string;
  public ChangePcpServiceUrl: string;
  public MemberPlanOperationServiceUrl: string;
  public CheckProviderHealthNetworkUrl: string;
  public AcknowledgeTermsOfUseUrl: string;
  public LoggingServiceUrl: string;
  public GetLatestHealthAssessmentSurveyUrl: string;
  public GetHealthAssessmentSurveyUrl: string;
  public GetHealthAssessmentSurveyResponsesForUserUrl: string;
  public HealthAssessmentResponseUrl: string;
  public HealthAssessmentResultsUrl: string;
  public HealthAssesmentResponseExtraHeaderCharacters: string;
  public HealthAssesmentResponseExtraFooterCharacters: string;
  public GetChangePcpReasonCodesUrl: string;
  public ChangePcpReasonCodesSitecoreUrl: string;
  public GetLanguageMenuUrl: string;

  public SocialNeedsSurveyParameter: string;
  public HealthAssessmentSurveyParameter: string;
  public SOGIFAQParameter: string;
  public SOGISurveyParameter: string;
  public BaseMemberSurveyUrl: string;
  public GetMemberSurveyUrl: string;
  public CreateMemberSurveyUrl: string;
  public SaveMemberSurveyUrl: string;
  public SubmitMemberSurveyUrl: string;

  // Common
  public EmptyString: string;
  public SpaceString: string;
  public AccessTokenName: string;
  public AuthorizeUserComponent: string;
  public ApplicationComponent: string;
  public TermsOfUseRoute: string;
  public ServerError: string;
  public BadResponseStatus: string;
  public CalOptimaText: string;
  public MinimumSuccessCode: number;
  public MaximumSuccessCode: number;
  public TimeoutValue: number;
  public ErrorDelay: number;
  public RetryAttempts: number;
  public HealthNetworkCodeUrlParameter: string;
  public LineOfBusinessCodeUrlParameter: string;
  public UrlParameterAndTag: string;
  public UrlParameterStartTag: string;
  public EqualOperator: string;
  public ChangePcpConfirmationRoute: string;
  public DatePickerFormat: string;
  public DateHashFormat: string;
  public ShaVersion: string;
  public DigestString: string;
  public DefaultPrintFormatValue: string;
  public Version: string = "Version";
  public PrintFormatSize14: string;
  public PrintFormatSize16: string;
  public PrintFormatSize18: string;
  public PrintFormatSize20: string;
  public PrintFormatSize22: string;
  public PrintFormatBraille: string;
  public ApplicationName: string;
  public CCNNetwork: string;
  public Yes: string;
  public No: string;
  public CurrentValue: string;
  public CODMEDA: string;
  public CODMEAX: string;
  public SessionStateKey: string;
  public SurveyStoragePrefix: string;
  public FXIRequestIDToken: string;
  public FXIPartialSystemError: string;
  public Available: string;
  public Active: string;
  public UserProfileEditSessionKey: string;

  // User Authentication
  public RegisterParameter: string;
  public UpdateParameter: string;
  public UpdateUserProfileParameter: string;
  public UpdateUserProfilePasswordParameter: string;
  public UpdateUserProfileComplete: string;
  public GetUserProfileParameter: string;
  public ValidateParameter: string;
  public ForgotUsernameLocation: string;
  public PasswordResetLocation: string;
  public ErrorMessageStatusAccountLocked: string;
  public PasswordExpiredLocalName: string;
  public PasswordExpiredLocalValue: string;
  public LoginPasswordExpiredLocalName: string;
  public LoginPasswordExpiredLocalValue: string;
  public RememberMeLocalName: string;
  public PasswordResetRequestLocalName: string;
  public PasswordResetRequestLocalValue: string;

  public PasswordMinimumLength: number;

  public MainRegistrationUrl: string;
  public ForgotUsernameUrl: string;
  public PasswordResetUrl: string;
  public SelfServiceRestartUrl: string;

  public LoginRelativePath: string;
  public MainRegistrationRelativePath: string;
  public ForgotUsernameRelativePath: string;
  public PasswordResetRelativePath: string;
  public SelfServiceRestartRelativePath: string;

  // Content Service Parameters
  public SitecoreServiceDashboardParameter: string;
  public SitecoreServiceMemberProfileParameter: string;
  public HeaderContentServiceParameter: string;
  public NavigatorContentServiceParameter: string;
  public SitecoreServiceRequestIDCardParameter: string;
  public SitecoreServicePrintFormatsLookupParameter: string;
  public SitecoreServicePrintMemberIdCardParameter: string;
  public SitecoreServiceGeneralInquiryParameter: string;
  public SitecoreServiceInterpretiveServiceParameter: string;
  public SitecoreServiceMemberMessageParameter: string;
  public SitecoreServiceAuthorizationParameter: string;
  public SitecoreServiceHealthAssessmentParameter: string;
  public SitecoreServiceHealthAppraisalParameter: string;
  public SitecoreServiceHealthAppraisalSurveyParameter: string;
  public SitecoreServiceHealthAppraisalSurveyViewOnlyParameter: string;
  public SitecoreServiceClaimParameter: string;
  public SitecoreServiceSearchForProviderParameter: string;
  public SitecoreServiceProviderSearchParameter: string;
  public CustomerServiceGeneralInquiryPostParamater: string;
  public CustomerServiceInterpretiveServicePostParamater: string;
  public UpdateMemberprofileSitecoreContentUrl: string;
  public SitecoreUpdateMemberProfileParameter: string;
  public SitecoreServiceFooterParameter: string;
  public SitecoreServiceFooterCardParameter: string;
  public ChangePcpRequestSitecoreServiceParameter: string;
  public ChangePcpConfirmationSitecoreServiceParameter: string;
  public ChangeHealthNetworkParameter: string;
  public SundayDatePickerCode: string;
  public SiteCoreServiceImportantDocumentsParameter: string;
  public DefaultHeaderLogoPath: string;
  public SitecoreServicePrivacyPolicyParameter: string;
  public SitecoreServiceTermsOfUseParameter: string;
  public SitecoreServiceChangePCPChangeHealthNetworkParameter: string;
  public SitecoreServiceChangePCPParameter: string;
  public SitecoreServiceChangeSearchForAProviderParameter: string;
  public SitecoreServiceProviderSearchResultsParameter: string;
  public SitecoreServiceRegistrationParameter: string;
  public SitecoreServiceSecondFactorParameter: string;
  public SiteFooterParameter: string;
  public SitecoreServiceLoginHelpParameter: string;
  public LoginParameter: string;
  public ForgotUsernameParameter: string;
  public PasswordResetParameter: string;
  public UserProfileParameter: string;
  public DfpJavascriptUrlParameter: string;
  public SitcoreBHVirtualVisitsParameter: string;
  // Print Member Id Card
  public PNGImageType: string;
  public FrontImageHtmlElementId: string;
  public BackImageHtmlElementId: string;
  public Asteriks: string;
  public MediCalFrontImagePath: string;
  public MediCalBackImagePath: string;
  public OCFrontImagePath: string;
  public OCBackImagePath: string;
  public OCCFrontImagePath: string;
  public OCCBackImagePath: string;
  public PDFIdCardFileName: string;
  public PDFFileOrientation: string;
  public CanvasDimensions: string;
  public MomentDateFormat: string;
  public DatePipeLanguage: string;
  public MediCalBoldFont: string;
  public MediCalSmallFont: string;
  public MediCalDataFont: string;

  public OCCFont: string;
  public OCFont: string;
  public OCBoldFont: string;
  public OCHealthPlanNumber: string;
  public OCMemberNameLabel: string;
  public OCMemberIDLabel: string;
  public OCPersonalCareCoordinatorPhoneLabel: string;
  public OCHealthNetworkLabel: string;
  public OCHealthNetworkPhoneLabel: string;
  public OCPCPGroupLabel: string;
  public OCPCPPhoneLabel: string;
  public OCRxBINLabel: string;
  public OCRxPCNLabel: string;
  public OCRxGroupLabel: string;
  public OCCMSContractNumber: string;

  public MediCalEffectiveDateLabel;
  public MediCalDOBLabel: string;
  public MediCalPcpLabel: string;
  public MediCalRxBinLabel: string;
  public MediCalRxPCNLabel: string;
  public MediCalRxGrpLabel: string;
  public MediCalVisionServicesLabel: string;
  public MediCalRxServicesLabel: string;
  public MediCalForMembersWhoMeetRequirementsLabel: string;
  public SitecoreServiceAuthorizationDetailsParameter: string;

  // Health Assessment
  public HealthAssessmentRecentSurvey: string;
  public HealthAssessmentIncompleteSurvey: string;

  // General Inquiry
  public MaxNumberOfCharacters: number;
  public MinNumberOfCharacters: number;

  // update member profile
  public FontAwesomeDoubleRightAngle: string;
  public FontAwesomeDoubleDownAngle: string;
  public AddressLinesMaxNumberOfCharacters: number;
  public CityMaxNumberOfCharacters: number;
  public PrimaryAddressLine1ControlName: string;
  public PrimaryAddressLine2ControlName: string;
  public MailingAddressLine1ControlName: string;
  public MailingAddressLine2ControlName: string;

  // Lob Values
  public MediCalLOBCode: string;
  public OneCareLOBCode: string;
  public OneCareConnectLOBCode: string;
  public PaceLOBCode: string;

  // Plan codes
  public KaiserCodeRegular: string;
  public KaiserCodeExpansion: string;
  public ChocAllianceCode: string;

  // Provider Search
  public PcpQueryParamName: string;
  public ZipcodeQueryParamName: string;
  public HealthNetworkQueryParamName: string;
  public ReasonForChangeQueryParamName: string;
  public SourceUrlQueryParamName: string;
  public Above18QueryParamName: string;
  public ProviderIdQueryParamName: string;
  public MemberGroupIdParamName: string;
  public MemberSubGroupIdParamName: string;
  public HealthNetworkAgeParam: string;
  public LobQueryParamName: string;
  public AgeQueryParamName: string;
  public IdQueryParamName: string;
  public HealthNetworkPlanCodeQueryParamName: string;
  public IsNetworkChangeAllowedQueryParamName: string;
  public HashedDateParamName: string;

  // Change PCP
  public OtherReasonForChange: string;
  public ChangePCPOption: string;
  public KeepCurrentPCPOption: string;
  public AssigneDefaultPCPOption: string;
  public KaiserOption: string;
  public MediCalHealthNeworksAllowedToChangePCPWithoutHN: Array<string>;
  public ChangeHnPcpResponseMessageStorageName: string;

  // Change HealthNetwork
  public ChangePCPChangeHealthNetworkLink: string;

  // Important Links
  public InactiveMembersCommonQuestionsContent: string;
  public CommonQuestionsContent: string;
  public InactiveMembersCommonFormsContent: string;
  public CommonFormsContent: string;

  // Session Timer
  public SesssionTimerContentServiceParameter: string;
  public TimeToShowSessionTimerBeforeSessionExpires: number;
  public SecondsPrefix: string;
  public SecondsLabel: string;
  public MinutesPrefix: string;
  public MinutesLabel: string;
  public TimerColon: string;
  public SessionTimerModalId: string;
  public FirstSessionTimeout: number;
  public SecondSessionTimeout: number;
  public FiftyNineSecondsInMillisSeconds: number;
  public SixtySecondsInMilliSeconds: number;
  public OneSecondInMilliSeconds: number;
  public TenMinutes: number;
  public TenSeconds: number;

  // Provider Search
  public ProviderSearchLanguagesURL: string;
  public ProviderSearchSpecialtiesURL: string;
  public ProviderSearchURL: string;
  public ProviderSearchHospitalsURL: string;

  // Provider Search Results
  public SortByProviderName: string;
  public SortByCity: string;
  public SortByZipCode: string;
  public SortByProviderSpecialty: string;
  public SortByDistance: string;
  // Routes

  // Header
  public BrandColorMedical: string;
  public BrandColorPace: string;
  public BrandColorOCC: string;
  public HeaderNavbarSolidClass: string;
  public HeaderNavbarId: string;
  public ScrollPositionOpacity: number;

  // Personal Representative
  public PersonalRepresentativePending: number;
  public PersonalRepresentativeDenied: number;
  public PersonalRepresentativeTermed: number;
  public ParentRelationshipCode: string;

    // Authorization
    public ReferralSummaryMemberServiceParameter: string = "authorizations/referralSummary";
    public ReferralDetailsMemberServiceParameter: string = "authorizations/referraldetails";

  // LoggingService
  public ErrorLevel: string;

  // MedImpact
  public MedImpactHashKey: string;

  // Public MedImpact time format
  public MedImpactTimeFormat: string;

  // Security Questions
  public SecurityQuestions: Array<KeyValue> = new Array<KeyValue>();
  public defaultIncorrectPasscodeMessage: string = "incorrect passcode";
  public defaultIncorrectAnswersMessage: string = "answers are incorrect";

  // Behaviral Health Virtual Visits
  public BehaviralHealthVirtualVisitsUrl: string;
  public UpdateSecurityQuestions(registrationTemplate: RegistrationTemplate) {
    if (registrationTemplate) {
      this.SecurityQuestions[0].text = registrationTemplate.Question1;
      this.SecurityQuestions[1].text = registrationTemplate.Question2;
      this.SecurityQuestions[2].text = registrationTemplate.Question3;
      this.SecurityQuestions[3].text = registrationTemplate.Question4;
      this.SecurityQuestions[4].text = registrationTemplate.Question5;
      this.SecurityQuestions[5].text = registrationTemplate.Question6;
      this.SecurityQuestions[6].text = registrationTemplate.Question7;
      this.SecurityQuestions[7].text = registrationTemplate.Question8;
      this.SecurityQuestions[8].text = registrationTemplate.Question9;
      this.SecurityQuestions[9].text = registrationTemplate.Question10;
    }
  }
  public GetSecurityQuestion(val: string) {
    for (let i = 0; i < this.SecurityQuestions.length; i++) {
      if (this.SecurityQuestions[i].value === val)
        return this.SecurityQuestions[i];
    }
    return null;
  }
  constructor(private config: AppConfig) {
    // *** Begin constants from configuration json file ***
    this.Environment = this.config.getConfig("Environment");
    this.BaseMemberPortalUrl = this.config.getConfig("BaseMemberPortalUrl");
    this.BaseMemberServiceUrl = this.config.getConfig("BaseMemberServiceUrl");
    this.BaseSecureAuthUrl = this.config.getConfig("BaseSecureAuthUrl");
    this.BaseSitecoreServiceUrl = this.config.getConfig(
      "BaseSitecoreServiceUrl"
    );
    this.RegionClientId = this.config.getConfig("RegionClientId");
    this.RegionRedirectUri = this.config.getConfig("RegionRedirectUri");
    this.BaseSitecoreUrl = this.config.getConfig("BaseSitecoreUrl");
    this.BaseAuthenticationServiceUrl = this.config.getConfig(
      "BaseAuthenticationServiceUrl"
    );
    this.TimeoutValue = this.config.getConfig("TimeoutValue");

    // *** END constants from configuration json file ***
    this.MemberPrivacyPolicyUrl = this.config.getConfig("PrivacyPolicy");
    this.MemberNoticeOfPrivacyPracticesUrl = this.config.getConfig(
      "NoticeOfPrivacyPractices"
    );

    // OAuth Configuration
    this.ClientId = this.RegionClientId;
    this.Scope = "openid profile phone email offline_access gallerymanagement";
    this.Issuer = "https://member.caloptima.org";
    this.Oidc = true;
    this.IdpServer = this.BaseSecureAuthUrl;
    //this.UserProfileUrl = this.IdpServer + 'selfservice';
    this.UserProfileUrl = this.BaseMemberPortalUrl + "#/user/userprofile";
    this.LogoutUrl = this.BaseMemberPortalUrl + "#/core/logoff";
    this.SessionTimeOutUrl = this.LogoutUrl;
    this.SitecoreContentServiceUrl =
      this.BaseSitecoreServiceUrl + "SitecoreContent/";
    this.ForgotUsernameLocation = "forgotusername";
    this.PasswordResetLocation = "passwordreset";

    // Authentication paramters & methods
    this.RegisterParameter = "Register";
    this.UpdateParameter = "Update";
    this.ValidateParameter = "ValidateUser";
    this.UpdateUserProfileParameter = "UpdateUserProfile";
    this.UpdateUserProfilePasswordParameter = "UpdateUserProfilePassword";
    this.UpdateUserProfileComplete = "UpdateUserProfileComplete";
    this.GetUserProfileParameter = "GetUserProfile";
    // Service Urls & Method Names
    this.MemberServiceUrl = this.BaseMemberServiceUrl + "member/5";
    this.MemberProfileServiceUrl = this.BaseMemberServiceUrl + "memberProfile";
    this.RequestIDCardServiceUrl =
      this.BaseMemberServiceUrl + "RequestIDCardByMail/";
    this.GeneralInquiryServiceUrl =
      this.BaseMemberServiceUrl + "GeneralInquiry/";
    this.InterpretiveServiceUrl =
      this.BaseMemberServiceUrl + "InterpretiveService/";
    this.TCPAUpdateUrl = this.BaseMemberServiceUrl + "tcpaupdate/";
    this.MemberMessagesUrl = this.BaseMemberServiceUrl + "GetMessages/";
    this.MemberMessageUpdateUrl = this.BaseMemberServiceUrl + "UpdateMessage/";
    this.MemberIdCardServiceUrl = this.BaseMemberServiceUrl + "memberIdCard/";
    this.RequestPrintMemberIdCardUrl =
      this.BaseMemberServiceUrl + "RequestPrintIdCard/";
    this.RequestDownloadMemberIdCardUrl =
      this.BaseMemberServiceUrl + "RequestDownloadIdCard/";
    this.UpdateMemberProfileServiceUrl =
      this.BaseMemberServiceUrl + "updateMemberProfileSection/";
    this.MemberRegistrationCompleteUrl =
      this.BaseMemberServiceUrl + "MemberRegistrationComplete/";
    this.MemberLanguagesServiceUrl =
      this.BaseMemberServiceUrl + "memberLanguages";
    this.FamilyRelationshipServiceUrl =
      this.BaseMemberServiceUrl + "familyRelationships";
    this.AlertServiceUrl = this.BaseMemberServiceUrl + "alerts";
    this.RedirectUri = this.RegionRedirectUri; // launching through IIS
    this.SendEmailServiceUrl = this.BaseMemberServiceUrl + "email/";
    this.ProviderServiceUrl = this.BaseMemberServiceUrl + "provider/";
    this.GetHealthNetworkServiceUrl = this.BaseMemberServiceUrl + "providerhn/";
    this.GetProviderDetailsServiceUrl =
      this.BaseMemberServiceUrl + "providerwithhn/";
    this.ChangePcpServiceUrl = this.BaseMemberServiceUrl + "changepcp";
    this.MemberPlanOperationServiceUrl =
      this.BaseMemberServiceUrl + "memberPlanOperation/";
    this.CheckProviderHealthNetworkUrl =
      this.ProviderServiceUrl + "IsProviderWithHealthNetwork";
    this.AcknowledgeTermsOfUseUrl =
      this.BaseMemberServiceUrl + "AcknowledgeTermsOfUse";
    this.LoggingServiceUrl = this.BaseMemberServiceUrl + "logError";
    this.GetLatestHealthAssessmentSurveyUrl =
      this.BaseMemberServiceUrl + "GetLatestHealthAssessmentSurvey";
    this.GetHealthAssessmentSurveyUrl =
      this.BaseMemberServiceUrl + "GetHealthAssessmentSurvey";
    this.GetHealthAssessmentSurveyResponsesForUserUrl =
      this.BaseMemberServiceUrl + "GetHealthAssessmentSurveyResponsesForUser";
    this.HealthAssessmentResponseUrl =
      this.BaseMemberServiceUrl + "HealthAssessmentResponse";
    this.HealthAssessmentResultsUrl =
      this.BaseMemberServiceUrl + "HealthAssessmentResults";
    this.HealthAssesmentResponseExtraFooterCharacters =
      '","Id":1691,"Exception":null,"Status":5,"IsCanceled":false,"IsCompleted":true,"CreationOptions":0,"AsyncState":null,"IsFaulted":false}"';
    this.HealthAssesmentResponseExtraHeaderCharacters = '"{"Result":"';
    this.GetChangePcpReasonCodesUrl = this.BaseMemberServiceUrl + "reasoncodes";
    this.ChangePcpReasonCodesSitecoreUrl = "view/LookUp~ChangePcpReasonCodes";
    this.GetLanguageMenuUrl = this.SitecoreContentServiceUrl + "languageMenu";

    // Survey Service Urls and Service Names
    this.SocialNeedsSurveyParameter = "view/socialNeeds";
    this.HealthAssessmentSurveyParameter = "view/healthAssessment";
    this.SOGIFAQParameter = "view/sogiFAQ";
    this.SOGISurveyParameter = "view/sogiSurvey";

    this.BaseMemberSurveyUrl = this.BaseMemberServiceUrl + "Survey/";
    this.GetMemberSurveyUrl = this.BaseMemberSurveyUrl + "SearchMemberSurvey";
    this.CreateMemberSurveyUrl = this.BaseMemberSurveyUrl + "CreateMemberSurvey";
    this.SaveMemberSurveyUrl = this.BaseMemberSurveyUrl + "SaveMemberSurvey";
    this.SubmitMemberSurveyUrl =
      this.BaseMemberSurveyUrl + "SubmitMemberSurvey";

    // Content Service Parameters
    this.SitecoreServiceDashboardParameter = "dashboard/";
    this.SitecoreServiceMemberProfileParameter = "memberProfile";
    this.HeaderContentServiceParameter = "portalMemberHeader";
    this.NavigatorContentServiceParameter = "view/portalMemberNavigator";
    this.SitecoreServiceRequestIDCardParameter = "requestIDCard";
    this.SitecoreServicePrintFormatsLookupParameter = "view/LookUp~PrintFormats";
    this.SitecoreServicePrintMemberIdCardParameter = "printMemberIdCard";
    this.SitecoreServiceGeneralInquiryParameter = "generalInquiry";
    this.SitecoreServiceInterpretiveServiceParameter = "view/interpretiveService";
    this.SitecoreServiceMemberMessageParameter = "memberMessage";
    this.SitecoreServiceAuthorizationParameter = "view/Authorizations";
    this.SitecoreServiceHealthAssessmentParameter = "healthAssessment";
    this.SitecoreServiceHealthAppraisalParameter = "HealthAppraisal";
    this.SitecoreServiceHealthAppraisalSurveyParameter =
      "HealthAppraisalSurvey";
    this.SitecoreServiceHealthAppraisalSurveyViewOnlyParameter =
      "HealthAppraisalSurveyViewOnly";
    this.SitecoreServiceClaimParameter = "claims";
    this.SitecoreServiceSearchForProviderParameter = "searchForProvider";
    this.SitecoreServiceProviderSearchParameter = "ProviderSearch";
    this.CustomerServiceGeneralInquiryPostParamater = "post";
    this.CustomerServiceInterpretiveServicePostParamater = "post";
    this.SitecoreUpdateMemberProfileParameter = "updateMemberProfile";
    this.SitecoreServiceFooterParameter = "memberPortalFooter";
    this.SitecoreServiceFooterCardParameter = "MemberFooterCard";
    this.ChangePcpRequestSitecoreServiceParameter = "changePcpRequest";
    this.ChangePcpConfirmationSitecoreServiceParameter =
      "changePcpConfirmation";
    this.ChangeHealthNetworkParameter = "view/changeHealthNetwork";
    this.SiteCoreServiceImportantDocumentsParameter =
      "linksToImportantDocuments";
    this.SitecoreServicePrivacyPolicyParameter = "privacyPolicy";
    this.SitecoreServiceTermsOfUseParameter = "termsOfUse";
    this.SitecoreServiceProviderSearchResultsParameter =
      "ProviderSearchResults";
    this.SitecoreServiceChangePCPChangeHealthNetworkParameter =
      "changePCPChangeHealthNetwork";
    this.SitecoreServiceChangePCPParameter = "view/changePCP";
    this.SitecoreServiceChangeSearchForAProviderParameter =
      "searchForAProvider";
    this.SitecoreServiceAuthorizationDetailsParameter = "view/AuthorizationDetails";
    this.ReferralDetailsMemberServiceParameter =
      "authorizations/referraldetails";
    this.SitecoreServiceRegistrationParameter = "Registration";
    this.SitecoreServiceSecondFactorParameter = "SecondFactor";
    this.SiteFooterParameter = "SiteFooter";
    this.SitecoreServiceLoginHelpParameter = "LoginHelp";
    this.LoginParameter = "MemberLogin";
    this.ForgotUsernameParameter = "ForgotUsername";
    this.PasswordResetParameter = "PasswordReset";
    this.UserProfileParameter = "UpdateUserProfile";
    this.SitcoreBHVirtualVisitsParameter = "BHVirtualVisits";
    // Common
    this.EmptyString = "";
    this.SpaceString = " ";
    this.AccessTokenName = "access_token";
    this.AuthorizeUserComponent = "authorize";
    this.ApplicationComponent = "dashboard";
    this.TermsOfUseRoute = "termsofuse";
    this.ServerError = "Server error";
    this.BadResponseStatus = "Bad response status: ";
    this.ErrorDelay = 500;
    this.RetryAttempts = 3;
    this.CalOptimaText = "CalOptima";
    this.MinimumSuccessCode = 200;
    this.MaximumSuccessCode = 299;
    this.HealthNetworkCodeUrlParameter = "hn=";
    this.LineOfBusinessCodeUrlParameter = "lob=";
    this.UrlParameterAndTag = "&";
    this.UrlParameterStartTag = "?";
    this.EqualOperator = "=";
    this.ChangePcpConfirmationRoute = "#/changepcpconfirmation/";
    this.DatePickerFormat = "mm/dd/yyyy";
    this.SundayDatePickerCode = "su";
    this.DefaultHeaderLogoPath = "./assets/Logo_Main.png";
    this.DateHashFormat = "MM/dd/yyyy";
    this.ShaVersion = "sha256";
    this.DigestString = "hex";
    this.DefaultPrintFormatValue = "Default Format";
    this.PrintFormatSize14 = "Large Print - Font Size 14";
    this.PrintFormatSize16 = "Large Print - Font Size 16";
    this.PrintFormatSize18 = "Large Print - Font Size 18";
    this.PrintFormatSize20 = "Large Print - Font Size 20";
    this.PrintFormatBraille = "Braille";
    this.ApplicationName = "MemberPortalClient";
    this.CCNNetwork = "CCNNTWK";
    this.CODMEDA = "CODMEDA";
    this.CODMEAX = "CODMEAX";
    this.CurrentValue = "(Current)";
    this.Yes = "yes";
    this.No = "no";
    this.SessionStateKey = "session_state";
    this.SurveyStoragePrefix = "survey-";
    this.FXIRequestIDToken = "{FXI Request ID}";
    this.FXIPartialSystemError = "System Error:";
    this.Available = "Available";
    this.Active = "ACTIVE";
    this.UserProfileEditSessionKey = "userprofileedit";

    // Health Assessment
    this.HealthAssessmentRecentSurvey = "Recent";
    this.HealthAssessmentIncompleteSurvey = "Incomplete";

    // General Inquiry
    this.MaxNumberOfCharacters = 1000;
    this.MinNumberOfCharacters = 4;

    // Print Memeber Id Card
    this.PNGImageType = "PNG";
    this.FrontImageHtmlElementId = "canvasFrontImage";
    this.BackImageHtmlElementId = "canvasBackImage";
    this.Asteriks = "*";
    this.MediCalFrontImagePath = "./assets/MCal_Front.jpg";
    this.MediCalBackImagePath = "./assets/MCal_Back.jpg";
    this.OCFrontImagePath = "./assets/OC_Front.jpg";
    this.OCBackImagePath = "./assets/OC_Back.jpg";
    this.OCCFrontImagePath = "./assets/OCC_Front.jpg";
    this.OCCBackImagePath = "./assets/OCC_Back.jpg";
    this.PDFIdCardFileName = "CalOptimaIdCard.pdf";
    this.PDFFileOrientation = "l";
    this.CanvasDimensions = "2d";
    this.MomentDateFormat = "MM/DD/YYYY";
    this.DatePipeLanguage = "en-US";
    this.MediCalBoldFont = "bold 16px Arial";
    this.MediCalSmallFont = "10px Arial";
    this.MediCalDataFont = "14px Arial";
    this.OCCFont = "12px Arial";
    this.OCBoldFont = "bold 16px Arial";
    this.OCFont = "14px Arial";
    this.OCHealthPlanNumber = "717-452-6385";
    this.OCMemberNameLabel = "Member Name:";
    this.OCMemberIDLabel = "Member ID:";
    this.OCPersonalCareCoordinatorPhoneLabel =
      "Personal Care Coordinator Phone:";
    this.OCHealthNetworkLabel = "Health Network:";
    this.OCHealthNetworkPhoneLabel = "Health Network Phone:";
    this.OCPCPGroupLabel = "PCP Group/Name:";
    this.OCPCPPhoneLabel = "PCP Phone:";
    this.OCRxBINLabel = "RxBIN:";
    this.OCRxPCNLabel = "RxPCN:";
    this.OCRxGroupLabel = "RxGroup:";
    this.OCCMSContractNumber = "H5433-001";

    this.MediCalEffectiveDateLabel = "Eff Date:";
    this.MediCalDOBLabel = "DOB:";
    this.MediCalPcpLabel = "PCP:";
    this.MediCalRxBinLabel = "RxBin:";
    this.MediCalRxPCNLabel = "RxPCN:";
    this.MediCalRxGrpLabel = "RxGrp:";
    this.MediCalVisionServicesLabel = "Vision Services:";
    this.MediCalRxServicesLabel = "Rx Services:";
    this.MediCalForMembersWhoMeetRequirementsLabel =
      "*For member who meet requirements";

    // Update member profile
    this.FontAwesomeDoubleDownAngle = "fa fa fa-angle-double-down";
    this.FontAwesomeDoubleRightAngle = "fa fa fa-angle-double-right";
    this.AddressLinesMaxNumberOfCharacters = 40;
    this.CityMaxNumberOfCharacters = 19;
    this.PrimaryAddressLine1ControlName = "primaryAddressLine1";
    this.PrimaryAddressLine2ControlName = "primaryAddressLine2";
    this.MailingAddressLine1ControlName = "mailingAddressLine1";
    this.MailingAddressLine2ControlName = "mailingAddressLine2";

    // LOB codes
    this.MediCalLOBCode = "MEDI-CAL";
    this.OneCareLOBCode = "MEDICARE";
    this.OneCareConnectLOBCode = "OCC";
    this.PaceLOBCode = "CALPACE";

    // Plan codes
    this.KaiserCodeRegular = "HMO04MC";
    this.KaiserCodeExpansion = "HMO04MX";
    this.ChocAllianceCode = "PHC20";

    // Provider Search params
    this.PcpQueryParamName = "pcp";
    this.ZipcodeQueryParamName = "zipcode";
    this.HealthNetworkQueryParamName = "hn";
    this.ReasonForChangeQueryParamName = "rfc";
    this.SourceUrlQueryParamName = "src";
    this.Above18QueryParamName = "above18";
    this.MemberGroupIdParamName = "memberGroupId";
    this.MemberSubGroupIdParamName = "memberSubGroupId";
    this.HealthNetworkAgeParam = "Age";
    this.LobQueryParamName = "lob";
    this.AgeQueryParamName = "age";
    this.IdQueryParamName = "id";
    this.HealthNetworkPlanCodeQueryParamName = "healthNetworkPlanCode";
    this.ProviderIdQueryParamName = "provid";
    this.IsNetworkChangeAllowedQueryParamName = "isHnChangeAllowed";
    this.HashedDateParamName = "param1";
    this.ProviderSearchLanguagesURL = "ProviderSearch/languages";
    this.ProviderSearchSpecialtiesURL = "ProviderSearch/specialties";
    this.ProviderSearchURL = "ProviderSearch/providers";
    this.ProviderSearchHospitalsURL = "ProviderSearch/hospitals";

    // Change PCP
    this.OtherReasonForChange = "Other";
    this.ChangePCPOption = "changePCP";
    this.KeepCurrentPCPOption = "currentPCP";
    this.AssigneDefaultPCPOption = "defaultPCP";
    this.KaiserOption = "kaiserOption";
    this.MediCalHealthNeworksAllowedToChangePCPWithoutHN = new Array<string>(
      "CODRESD",
      "CODRESX",
      "CODCNET",
      "CODCNEX",
      "CODMEDA"
    );
    this.ChangePCPChangeHealthNetworkLink = "/changepcpchangehealthnetwork";
    this.ChangeHnPcpResponseMessageStorageName =
      "changeHnPcpRequestMessageStore";

    // Change Health Network

    // Important Links
    this.InactiveMembersCommonQuestionsContent =
      "InactiveMembersCommonQuestions";
    this.CommonQuestionsContent = "CommonQuestions";
    this.InactiveMembersCommonFormsContent = "InactiveMembersCommonForms";
    this.CommonFormsContent = "CommonForms";

    // SessionTimer
    this.SesssionTimerContentServiceParameter = "SessionTimer";
    this.TimeToShowSessionTimerBeforeSessionExpires = 300000;
    this.SecondsLabel = "seconds";
    this.SecondsPrefix = "00:";
    this.MinutesLabel = "minutes";
    this.MinutesPrefix = "0";
    this.TimerColon = ":";
    this.SessionTimerModalId = "#myModal";
    this.FirstSessionTimeout = 30000;
    this.SecondSessionTimeout = 1000;
    this.FiftyNineSecondsInMillisSeconds = 59000;
    this.SixtySecondsInMilliSeconds = 60000;
    this.OneSecondInMilliSeconds = 1000;
    this.TenMinutes = 10;
    this.TenSeconds = 10;

    // Provider Search Results
    this.SortByCity = "City";
    this.SortByProviderName = "LastName";
    this.SortByProviderSpecialty = "PrimarySpecialty";
    this.SortByZipCode = "ZipCode";
    this.SortByDistance = "DistanceInMiles";
    // Logging Service
    this.ErrorLevel = "Error";

    //MedImpact Hash Key
    this.MedImpactHashKey = "pSDZfnqaAg891XiTxfLWMCHVFwW6Jp";
    this.MedImpactTimeFormat = "YYYY-MM-DD HH:mm:ss";

    //Header
    this.BrandColorMedical = "brandmcal";
    this.BrandColorPace = "brandpace";
    this.BrandColorOCC = "brandocc";
    this.HeaderNavbarSolidClass = "solid";
    this.HeaderNavbarId = "headerNavbar";
    this.ScrollPositionOpacity = 200;

    this.BehaviralHealthVirtualVisitsUrl = "bhvirtualvisit";

    this.SecurityQuestions.push({
      key: "1",
      value: "What city were you born in?",
      text: "What city were you born in?",
    });
    this.SecurityQuestions.push({
      key: "2",
      value: "What is your spouse's mother's maiden name?",
      text: "What is your spouse's mother's maiden name?",
    });
    this.SecurityQuestions.push({
      key: "3",
      value: "What is the first name of your favorite childhood friend?",
      text: "What is the first name of your favorite childhood friend?",
    });
    this.SecurityQuestions.push({
      key: "4",
      value: "What is your maternal grandmother's first name?",
      text: "What is your maternal grandmother's first name?",
    });
    this.SecurityQuestions.push({
      key: "5",
      value: "What was your favorite place to visit as a child?",
      text: "What was your favorite place to visit as a child?",
    });
    this.SecurityQuestions.push({
      key: "6",
      value: "In what city did you meet your spouse/significant other?",
      text: "In what city did you meet your spouse/significant other?",
    });
    this.SecurityQuestions.push({
      key: "7",
      value: "What is the furthest city you have traveled before you were 18?",
      text: "What is the furthest city you have traveled before you were 18?",
    });
    this.SecurityQuestions.push({
      key: "8",
      value: "What is your favorite hobby?",
      text: "What is your favorite hobby?",
    });
    this.SecurityQuestions.push({
      key: "9",
      value: "What are the last 4 digits of your driver's license number?",
      text: "What are the last 4 digits of your driver's license number?",
    });
    this.SecurityQuestions.push({
      key: "10",
      value: "Where did you go on your honeymoon?",
      text: "Where did you go on your honeymoon?",
    });

    // Personal Representative
    this.PersonalRepresentativePending = 1;
    this.PersonalRepresentativeDenied = 3;
    this.PersonalRepresentativeTermed = 4;
    this.ParentRelationshipCode = "CAL2";

    // User Authentication
    this.ErrorMessageStatusAccountLocked = "Account is locked out.";

    this.PasswordExpiredLocalName = "login-cleanup";
    this.LoginPasswordExpiredLocalName = "login-showerrmsg";
    this.RememberMeLocalName = "login-acc";

    this.PasswordExpiredLocalValue = "passwordResetRequired";
    this.LoginPasswordExpiredLocalValue = "tempPasswordInvalid";
    this.PasswordResetRequestLocalName = "passwordresetrequest";
    this.PasswordResetRequestLocalValue = "true";

    this.LoginRelativePath = "#/user/login";
    this.MainRegistrationRelativePath = "#/user/registration";
    this.ForgotUsernameRelativePath = "#/user/forgotusername";
    this.PasswordResetRelativePath = "#/user/passwordreset";
    this.SelfServiceRestartRelativePath = "secureauth5/restart.aspx";

    this.LoginUrl = this.BaseMemberPortalUrl + this.LoginRelativePath;
    this.MainRegistrationUrl =
      this.BaseMemberPortalUrl + this.MainRegistrationRelativePath;
    this.ForgotUsernameUrl =
      this.BaseMemberPortalUrl + this.ForgotUsernameRelativePath;
    this.PasswordResetUrl =
      this.BaseMemberPortalUrl + this.PasswordResetRelativePath;
    this.SelfServiceRestartUrl =
      this.BaseSecureAuthUrl + this.SelfServiceRestartRelativePath;

    this.PasswordMinimumLength = 8;
  }
}
