<!-- <div class="foot-pdf">
       
        {{siteFooterTemplate?.LOBContent}} 
        <br><br>
        
        <div [innerHTML] = "siteFooterTemplate?.AdobeAcquisition"></div>
    
        <br><br>
        <a href="https://get.adobe.com/reader/" target="_blank"><img src="assets/images/get_adobe_reader.gif"></a>
    
    </div> -->
    
    <footer class="footer ">
    
        <!-- Static Links //-->
        <div class="foot-static-wrapper">
            <div class="foot-links"><a href="{{siteFooterTemplate?.CaloptimaUrl || 'https://www.caloptima.org'}}" 
                target="_blank" class="foot-nowrap-links">{{siteFooterTemplate?.CaloptimaLabel || 'calOptima.org'}}</a></div>
            <div class="foot-links"><a href="{{siteFooterTemplate?.ContactUsUrl || 'https://www.caloptima.org/en/ContactUs.aspx'}}"
                target="_blank" class="foot-nowrap-links">{{siteFooterTemplate?.ContactUsLabel || 'contact Us'}}</a></div>
            <div class="foot-links"><a
                    href="{{siteFooterTemplate?.CommonFormsUrl || 'https://www.caloptima.org/en/ForMembers/Medi-Cal/MemberDocuments/CommonForms.aspx'}}"
                target="_blank" class="foot-nowrap-links">{{siteFooterTemplate?.CommonFormsLabel || 'common Forms'}}</a></div>
            <div class="foot-links"><a href="{{siteFooterTemplate?.ResourcesUrl || 'https://www.caloptima.org/en/Resources.aspx'}}"
                target="_blank" class="foot-nowrap-links">{{siteFooterTemplate?.ResourcesLabel || 'Resources'}}</a></div>
            <div class="foot-links" *ngIf="authenticated"><a href="{{siteFooterTemplate?.TermsOfUseUrl || '#/termsofuse'}}" 
                target="_self" class="foot-nowrap-links">{{siteFooterTemplate?.TermsOfUseLabel || 'member Portal Terms of Use'}}</a>
            </div>
            <div class="foot-links"><a href="{{siteFooterTemplate?.WebsitePrivacyPolicyUrl || 'https://www.caloptima.org/en/WebsitePrivacyPolicy.aspx'}}"
                target="_blank" class="foot-nowrap-links">{{siteFooterTemplate?.WebsitePrivacyPolicyLabel || 'website Privacy Policy'}}</a></div>
            <div class="foot-links"><a
                    href="{{siteFooterTemplate?.NoticeOfPrivacyPracticesUrl || 'https://www.caloptima.org/~/media/Files/CalOptimaOrg/508/Members/Privacy/2017_NoticeOfPrivacyPractices_E_508.ashx'}}"
                    target="_blank" class="foot-nowrap-links">{{siteFooterTemplate?.NoticeOfPrivacyPracticesLabel || 'notice of Privacy Practices'}}</a></div>
            <div class="foot-links"><a href="{{siteFooterTemplate?.ReportFraudAbuseUrl || 'https://www.caloptima.org/en/About/GeneralCompliance/HealthCareFraud.aspx'}}"
                    target="_blank" class="foot-nowrap-links foot-abuse">{{siteFooterTemplate?.ReportFraudAbuseLabel || 'report Fraud &amp; Abuse'}}</a></div>
        </div>
    
        <!-- Copyright //-->
        <div class="foot-copy-wrapper">
            <div class="foot-copy">
                    {{siteFooterTemplate?.Copyright || 'copyright &copy; 2021 CalOptima Health, A Public Agency - All Rights Reserved'}}
            </div>
        </div>
    
    </footer>