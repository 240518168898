export const environment = {
  production: true,
  forbiddenNumbers: [
    "000-000-0000",
    "111-111-1111",
    "222-222-2222",
    "333-333-3333",
    "444-444-4444",
    "555-555-5555",
    "666-666-6666",
    "777-777-7777",
    "888-888-8888",
    "999-999-9999",
    "123-456-7890",
    "987-654-3210",
    "222-333-4444",
    "714-246-8400",
    "714-246-8600",
    "888-587-8088",
  ],
  exclusionMapHN: {
    HMO04MC: new Date("2023-11-01"),
    SRG66MC: new Date("2023-12-01"),
    SRG65MC: new Date("2023-12-01"),
    PMG66MA: new Date("2023-12-01"),
    PMG52MA: new Date("2023-12-01"),
  },
  disableSurveys: [
    { code: 5, name: "SOGI" }
  ],
  disableVirtualVisits: false
};
