import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnDestroy,
} from "@angular/core";
import { combineLatest, Observable, Subscription } from "rxjs";

import { NavigatorSitecoreService } from "../shared/services/navigator-sitecore.service";
import { NavigatorSitecoreTemplate } from "../shared/navigator-sitecore-template";
import { MemberProfileService } from "../../member-portal/shared/services/member-profile.service";
import { MemberProfile } from "../../member-portal/shared/member-profile";
import { Configuration } from "../../app.constants";
import { MemberPlanOperation } from "../../member-portal/shared/member-plan-operation";
import { ProviderLinkService } from "../shared/services/provider-link.service";
import { MemberLobProviderLinksModel } from "../shared/member-lob-provider-sitecore-template";
import { SearchForProviderSitecoreTemplate } from "../shared/search-for-provider-sitecore-template";
import { map, switchMap } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { Md5 } from "ts-md5/dist/md5";
import { Moment } from "moment";
import { OAuthService } from "angular-oauth2-oidc";
import { HeaderSitecoreTemplate } from "../shared/header-sitecore-template";
import { MedImpactComponent } from "../med-impact/med-impact.component";
import {
  LanguageService,
  INotifyChange,
} from "../shared/services/language.service";
import { SubSink } from "subsink";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-navigator",
  templateUrl: "./navigator.component.html",
  styleUrls: ["./navigator.component.scss"],
})
export class NavigatorComponent implements OnInit, OnDestroy {
  public navigatorContent: NavigatorSitecoreTemplate =
    new NavigatorSitecoreTemplate();
  public memberProfile: MemberProfile = new MemberProfile();
  public memberPlanOperation: MemberPlanOperation = new MemberPlanOperation();
  public message: string;
  public providerUrl: string;
  public facilityUrl: string;
  public behavorialHealthUrl: string;
  public pharmacyUrl: string;
  public visionUrl: string;
  public dentalUrl: string;
  public memberProviderLinks: MemberLobProviderLinksModel =
    new MemberLobProviderLinksModel();
  private searchForProviderSitecoreTeamplate: SearchForProviderSitecoreTemplate =
    new SearchForProviderSitecoreTemplate();
  private subscriptions = new SubSink();
  public disableForPersonalRepresentative = false;
  public disableVirtualVisitsLink: boolean = false;
  @ViewChild("medImpactComponent", { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  constructor(
    public configuration: Configuration,
    private navigatorService: NavigatorSitecoreService,
    private memberProfileService: MemberProfileService,
    private providerLinksService: ProviderLinkService,
    private languageService: LanguageService,
    private authService: OAuthService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private config: Configuration
  ) {}

  ngOnInit() {
    try {
      this.disableVirtualVisitsLink = environment.disableVirtualVisits;
      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$,
      ]).subscribe((result) => {
        this.memberProfile = this.memberProfileService.memberProfile;
        this.onLoad();
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoad() {
    this.navigatorService.getNavigatorSitecoreContent().subscribe(
      (response) => {
        this.navigatorContent = response;
      },
      (error) => {
        this.message = <any>error;
      }
    );

    if (this.memberProfile) {
      this.providerLinksService.getProviderSearchTemplate().subscribe((res) => {
        this.searchForProviderSitecoreTeamplate = res;

        if (this.memberProfile.PersonalRepresentative) {
          this.disableForPersonalRepresentative =
            this.memberProfile.PersonalRepresentative
              .PersonalRepresentativeStatusId ===
              this.configuration.PersonalRepresentativePending ||
            this.memberProfile.PersonalRepresentative
              .PersonalRepresentativeStatusId ===
              this.configuration.PersonalRepresentativeDenied;
        }
        this.memberProviderLinks =
          this.providerLinksService.getLobProviderLinks(
            this.searchForProviderSitecoreTeamplate,
            this.memberProfile.LOB.LOBCode
          );
        if (this.memberProfile.IsEligible) {
          this.memberProfileService
            .getMemberPlanAllowedOperation()
            .subscribe((resp) => {
              this.memberPlanOperation = resp;
            });
        }

        this.memberProfile.HasAccessToMedImpact = true;
      });
    }
  }

  public showSurveyLink(code: number) {
    const exists = environment.disableSurveys.some(o => o.code === code);
    return !exists; // Show it if it not exists on the disable list.
  }

  // logic to show Change PCP/HealthNetwork Link
  // 1. Has to be eligible. Logic should be the same in EligibilityStatusAccessGuard object.
  // 2. Either has the ability for network change
  //    or pcp change from Member plan table in database. Logic should be the same in MemberPlanAccessGuard object
  // 3. Not a PACE member. Logic should be the same in PaceAccessGuard object
  // 4. Not a MediCal member if VIP restricted. Logic should be the same in VIPRestrictedAccessGuard object.
  public showChangePCPHealthNetworkLink() {
    let showChangeLink = false;

    if (
      this.memberProfile.IsEligible &&
      (this.memberPlanOperation.IsNetworkChangeAllowed ||
        this.memberPlanOperation.IsProviderChangeAllowed) &&
      this.memberProfile.LOB.LOBCode !== this.configuration.PaceLOBCode &&
      !this.isMediCalAndVipRestricted()
    ) {
      showChangeLink = true;
    }
    return showChangeLink;
  }

  public showInterpretiveServiceLink() {
    let showLink = false;

    if (
      this.memberProfile.IsEligible &&
      this.memberProfile.LOB.LOBCode !== this.configuration.PaceLOBCode &&
      (this.memberProfile.HealthNetworkClassCode === "CCN" ||
        this.memberProfile.HealthNetworkClassCode === "COD")
    ) {
      showLink = true;
    }
    return showLink;
  }

  public isMediCalAndVipRestricted(): boolean {
    let ismedicalandviprestricted = false;

    if (
      this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      this.memberProfile.MemberHasRestrictedVipCode
    ) {
      ismedicalandviprestricted = true;
    }
    return ismedicalandviprestricted;
  }

  // redirect to MedImpact using SSO then remove from DOM
  public goToMedImpact() {
    if (this.memberProfile.HasAcknowledgedTermsOfUse === true) {
      this.viewContainerRef.clear();
      let componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          MedImpactComponent
        );
      let componentRef =
        this.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.SetValues(this.memberProfile);

      setTimeout(() => {
        this.viewContainerRef.clear();
      }, 2000);
    }
  }

  // Logout via IdP
  public logOut() {
    this.authService.logOut(false);
    this.memberProfileService.memberProfile = null;
    sessionStorage.removeItem(this.configuration.SessionStateKey);
    window.location.href = this.config.RedirectUri;
  }

  // Redirect to SecureAuth (IdP) Self Service realm
  public redirectToUserProfile() {
    sessionStorage.setItem(
      this.configuration.UserProfileEditSessionKey,
      this.configuration.Yes
    );
    window.location.href = this.configuration.UserProfileUrl;
  }
}
