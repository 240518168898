import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService, INotifyChange } from '../shared/services/language.service';
import { LanguageMenuItem } from '../../member-portal/shared/languageMenuItem';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, OnDestroy {

  public languageMenuItems: Array<LanguageMenuItem> = new Array<LanguageMenuItem>();
  private subscription: Subscription;
  public message: string;
  
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if(change.propName === 'languageCode') {
          this.onLoad();
        }
      });
    }
    catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
  onLoad() {
    this.languageService.getLanguageMenuItems()
    .subscribe(response =>{
      this.languageMenuItems = response;
    },
      error => {
        this.message = <any>error;
      }
    );
  }

  public setLanguage(lang: string){
    this.languageService.languageCode = lang;
  }
}
